import { svgs } from '@/utils/Images'
import { FC, SVGProps } from 'react'
export const CaretIcon: FC<{ contentOpen: boolean } & SVGProps<SVGSVGElement>> = ({ contentOpen, className, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`text-cyan-v2 transition-transform duration-300 ${contentOpen ? 'rotate-90' : '-rotate-90'} ${className || 'h-5 w-5'}`}
      viewBox='0 0 11 18'
      fill='none'
      {...rest}
    >
      {svgs.arrow_prev}
    </svg>
  )
}
export default CaretIcon
