import TagManager from 'react-gtm-module'

export function useGTM() {
  /**
   * Initialize Google Tag Manager via react-gtm-module.
   */
  const initGTM = () => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && typeof window !== 'undefined') {
      try {
        const tagManagerArgs = {
          gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
        }
        // eslint-disable-next-line no-constant-condition
        TagManager.initialize(tagManagerArgs)
      } catch (error) {
        console.error('GTM Initialization Error: ', error)
      }
    }
  }

  const logEventGTM = (params?: any) => {
    if (typeof window !== 'undefined') {
      try {
        // eslint-disable-next-line no-constant-condition
        TagManager.dataLayer(params)
      } catch (error) {
        console.error('GTM Log Event Error: ', error)
      }
    }
  }

  return { initGTM, logEventGTM }
}
