import { ButtonHTMLAttributes, FC, ReactElement } from 'react'

export const IconButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, children, ...rest }): ReactElement => {
  return (
    <button
      {...rest}
      className={`flex items-center justify-center rounded-md border text-cyan-v2 transition-colors duration-300 enabled:cursor-pointer enabled:border-stone-300 enabled:bg-white/100 disabled:cursor-not-allowed disabled:border-stone-200 disabled:bg-stone-50 disabled:text-cyan-500/75 enabled:has-hover:hover:border-amber-v1 enabled:has-hover:hover:bg-amber-v1 enabled:has-hover:hover:text-white/100 ${
        className || ''
      }`}
    >
      {children}
    </button>
  )
}
export default IconButton
