import ReactGA4 from 'react-ga4'

export function useGA() {
  const initGA = () => {
    if (process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID && typeof window !== 'undefined') {
      try {
        ReactGA4.initialize(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID)
      } catch (error) {
        console.error('GA Initialization Error: ', error)
      }
    }
  }

  const logEventGA = (action: string, params?: any) => {
    if (typeof window !== 'undefined') {
      try {
        ReactGA4.gtag('event', action, params)
      } catch (error) {
        console.error('GA Log Event Error: ', error)
      }
    }
  }

  return { initGA, logEventGA }
}
