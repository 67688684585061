import { API_URL } from '@/utils/Consts'
import { Action1, SupportedOAuthProviders } from '@/utils/Types'
import { appUtils } from 'lib/AppUtils'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import type { UrlObject } from 'url'
import axios from '../lib/axios'
import type { CartCount } from '../models/Response'
import { useLanguage } from './Contexts'
import { useGlobalMutate, useMounted } from './Index'

export const useAuth = ({ middleware = '', redirectIfAuthenticated = '' } = {}) => {
  const router = useRouter()
  const isMounted = useMounted()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const { errors: errStr } = useLanguage()

  const {
    data: user,
    isValidating: userValidating,
    isLoading: userLoading,
    error: userError,
    mutate: mutateUser,
  } = useSWRImmutable(
    API_URL.getUserInfo,
    url =>
      axios
        .get(url)
        .then(res => res.data.data)
        .catch(error => {
          throw error
        }),
    { shouldRetryOnError: false }
  )

  const { mutate: swrMutate } = useSWRConfig()

  const csrf = () => axios.get('/sanctum/csrf-cookie')
  const doAuth = async (url: string, props: {}, onSuccess?: Action1<any>) => {
    setIsLoading(true)
    setErrors('')
    await csrf()
    await axios
      .post(url, props)
      .then(res => {
        if (res && res.data && res.data.code != '0') {
          if (isMounted) setErrors(appUtils.getErrorMsgByCode(res.data.code, errStr))
        } else {
          mutateUser()
          swrMutate(API_URL.cartCount)
          onSuccess?.(res)
        }
        if (isMounted) setIsLoading(false)
      })
      .catch(e => {
        if (isMounted) {
          setIsLoading(false)
          setErrors(appUtils.handleApiError(e, errStr))
        }
      })
  }

  const getSocialLoginLink = async (provider: SupportedOAuthProviders) => {
    setErrors('')
    setIsLoading(true)
    await axios
      .get(API_URL.socialLogin + provider)
      .then(response => {
        if (response.status === 200) {
          return router.push(response.data.data.url)
        }
      })
      .catch(e => {
        if (isMounted) {
          setIsLoading(false)
          setErrors(appUtils.handleApiError(e, errStr))
        }
      })
  }

  const mutateOpts = useMemo(
    () => ({
      optimisticData: null, // data to immediately update the client cache
      revalidate: false, // should the cache revalidate
      populateCache: false, // should the result of the remote mutation be written to the cache
      rollbackOnError: false, // should the cache rollback if the remote mutation errors
      throwOnError: false,
    }),
    []
  )
  const swrClearCache = useGlobalMutate(
    [
      API_URL.getUserInfo, // mutate user info
      API_URL.getUserAddresses,
      API_URL.getUserOrder,
      API_URL.getUserOrders,
      API_URL.userCashBalance,
      API_URL.userCashTrans,
      API_URL.useCreditBalance,
      API_URL.userCreditTrans,
      API_URL.topUpHistory,
      API_URL.allUserTix,
    ],
    null,
    mutateOpts
  )
  const localMutate = useCallback(() => {
    const newCartCnt: CartCount = { id: 0, items: 0, grant_total: 0 }
    swrMutate(API_URL.cartCount, newCartCnt, { ...mutateOpts, optimisticData: newCartCnt })
    swrClearCache() // clear swr cache
  }, [])

  /**
   * @param localMutation Mutate the user locally without refetching user info from remote
   * @param redirect Redirect path after local mutation
   * @returns
   */
  const logout = async (localMutation?: Boolean, redirect?: UrlObject | string) => {
    if (localMutation) {
      localMutate()
      if (redirect) {
        router.push(redirect)
      }
    } else if (!userError) {
      const res = await axios.post(API_URL.logout)
      if (res?.status === 204) {
        localMutate()
        router.push('/login') // don't refresh
      } else {
        window.location.pathname = '/login' // refresh page
      }
    } else {
      window.location.pathname = '/login' // refresh page
    }
    document.cookie = `rkrc=${''}`
  }

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      router.push(redirectIfAuthenticated)
    }
    if (middleware === 'auth' && userError) logout()
  }, [user, userError])

  // Get Social-Login or Reset-Password or Verify-Email Error message via query
  useEffect(() => {
    if (!router.isReady) return
    if (['/login', '/reset-password', '/user/email-verify'].indexOf(router.pathname) > -1 && router.query.error) {
      setErrors(appUtils.getErrorMsgByCode(router.query.error.toString(), errStr))
    }
  }, [router.query])
  return {
    user,
    userError,
    mutateUser,
    isMutating: userValidating || userLoading,
    isLoading,
    setIsLoading,
    errors,
    doAuth,
    logout,
    getSocialLoginLink,
  }
}
