import { Action0, Action1 } from './Types'

declare global {
  interface Array<T> {
    getLast(): T | null
    getFirst(): T | null
    getLastIndex(): number | null
    isEmpty(): boolean
    remove(predicate: (item: T) => boolean): boolean
    pushAll(arr: T[]): void
    pushAllNonNull(arr: T[]): void
    filterNull<T>(): T[]
  }
  interface String {
    isBlank(): boolean
    templated(...args: (string | number)[]): string
  }
  interface Promise<T> {
    cancellableThen(callback: Action1<T>): Action0
  }
}

// arrays
if (!Array.prototype.getLast) {
  Array.prototype.getLast = function () {
    if (this.length === 0) {
      return null
    }

    return this[this.length - 1]
  }
}

if (!Array.prototype.getFirst) {
  Array.prototype.getFirst = function () {
    if (this.length === 0) {
      return null
    }

    return this[0]
  }
}

if (!Array.prototype.getLastIndex) {
  Array.prototype.getLastIndex = function () {
    if (this.length === 0) {
      return null
    }

    return this.length - 1
  }
}

if (!Array.prototype.isEmpty) {
  Array.prototype.isEmpty = function () {
    return this.length === 0
  }
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function <T>(predicate: (item: T) => boolean) {
    const i = this.findIndex(predicate)
    if (i >= 0) {
      this.splice(i, 1)
    }

    return i >= 0
  }
}

if (!Array.prototype.pushAll) {
  Array.prototype.pushAll = function <T>(arr: T[]) {
    for (const x of arr) {
      this.push(x)
    }
  }
}

if (!Array.prototype.pushAllNonNull) {
  Array.prototype.pushAllNonNull = function <T>(arr: T[]) {
    for (const x of arr) {
      if (x !== undefined && x !== null) this.push(x)
    }
  }
}
if (!Array.prototype.filterNull) {
  Array.prototype.filterNull = function () {
    return this.filter(x => x !== undefined && x !== null)
  }
}

// strings

if (!String.prototype.isBlank) {
  String.prototype.isBlank = function () {
    return this.trim() === ''
  }
  String.prototype.templated = function (...args: (string | number)[]) {
    let i = 0
    return this.replace(/%d/g, () => {
      return args[i++] + ''
    })
  }
}

// promises
if (!Promise.prototype.cancellableThen) {
  Promise.prototype.cancellableThen = function <T>(callback: Action1<T>) {
    let shouldCancel = false
    this.then(param => {
      if (shouldCancel) {
        return
      }

      callback(param)
    })

    return () => (shouldCancel = true)
  }
}
