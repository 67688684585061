import { User } from '@models/Index'
import Script from 'next/script'
import { FC, useEffect } from 'react'

export const KlaviyoScript: FC<{ user: User }> = ({ user }) => {
  useEffect(() => {
    if (user && typeof window?.['klaviyo']?.identify != 'undefined') {
      const event = {
        $email: user.email || null,
        $first_name: user.profile?.first_name || null,
        $last_name: user.profile?.last_name || null,
        $country: '',
      }
      window['klaviyo'].identify(event)
    }
  }, [user])
  return (
    <Script id='klaviyo-object'>{`!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();`}</Script>
  )
}
export default KlaviyoScript
