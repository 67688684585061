import { Action0 } from '@/utils/Types'
import { appUtils } from 'lib/AppUtils'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { mutate, MutatorOptions } from 'swr'

export function useMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const useRenderCounter = label => {
  const counter = useRef(0)
  counter.current++
  console.log(`RENDER COUNTER: ${label} rendered ${counter.current} times`)
}

export function useScrollable(scrollable: boolean) {
  useEffect(() => {
    if (scrollable) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [scrollable])
}

export function useClickOutside(ref: MutableRefObject<any>, fn: Action0) {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      fn()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
}

export const useGlobalMutate = (targets: string | Array<string>, data?: any, opts?: boolean | MutatorOptions<any>) => {
  return useCallback(
    () =>
      mutate(
        key =>
          Array.isArray(key)
            ? !appUtils.isEmpty(key?.[0]) && (Array.isArray(targets) ? targets?.some(target => key[0].includes(target)) : key[0].includes(targets))
            : typeof key === 'string' && (Array.isArray(targets) ? targets?.some(target => key.includes(target)) : key.includes(targets)),
        data,
        opts
      ),
    [targets]
  )
}
